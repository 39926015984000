<template>
  <div>
    <!-- {{ targets[27] }} removableSort sortable -->
    <DataTable :value="targets" tableClass="table table-striped table-bordered" stripedRows responsiveLayout="scroll"
      :loading="loading" :paginator="targets.length > 10" :rows="10" rowIndexVar="index" v-model:filters="filters"
      filterDisplay="menu" :globalFilterFields="['TargetId', 'type', 'description', 'tags', 'date']">
      <template #header="">
        <div class="flex justify-content-between align-items-center">
          <!-- clear filter button -->
          <!-- <Buttons type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined"
            @click="clearFilter()" /> -->
          <span class="p-input-icon-right">
            <svg-icon class="button-icon" type="mdi" :path="magnify"></svg-icon>
            <InputText v-model="filters['global'].value" placeholder="Target Filter" />
          </span>
          <div class="target-create-btn flex align-items-center">
            <!-- Refresh button -->
            <!-- <Buttons class="p-button-outlined p-button-sm" @click="refresh()" style="margin-right: 0.5rem"><svg-icon
                type="mdi" :path="refreshicon" class="button-icon"></svg-icon></Buttons>  -->
            <Buttons :style="clientColor" label="Target erstellen" class="p-button-sm" @click="openCreateModal()" />
          </div>
        </div>
      </template>

      <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"> </Column>
      <Column field="tags" header="Tags" dataType="tags">
        <template #body="{ data }">
          {{ Array.isArray(data.tags) ? data.tags.join(", ") : data.tags }}
        </template>
      </Column>
      <!-- <Column field="date" header="Datum" filterField="date" dataType="date" style="width: 10px">
        <template #body="{ data }">
          {{ data.date }}
        </template>
        <template #filter="{ filterModel }">
          <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />
        </template>
      </Column>  -->
      <Column class="table table-striped table-bordered" style="width: 14rem; " bodyStyle="text-align:right">
        <template #body="{ data }">

          <div class="material-hover" @click="handleClickEdit(data.TargetId)">
            <svg-icon type="mdi" :path="pencil" class="button-icon"></svg-icon>
          </div>
          <div class="material-hover" @click="handleClickDelete(data.TargetId)">
            <svg-icon type="mdi" :path="garbagebin" class="button-icon"></svg-icon>
          </div>

        </template>
      </Column>
    </DataTable>

    <ConfirmDialog></ConfirmDialog>
    <RochefortDialog header="Neues Target erstellen" v-model:visible="displayCreateModal" :style="{ width: '50vw' }"
      :modal="true">
      <div class="flex justify-content-center">
        <form @submit.prevent="handleSubmit(!v$.$invalid)" class="form-new-target p-fluid">
          <div class="field">
            <label for="type" :class="{ 'p-error': v$.type.$invalid && submitted }">Typ*</label>
            <Dropdown id="type" v-model="v$.type.$model" :options="newTarget.targetTypes" optionLabel="name"
              placeholder="Typ" :class="{ 'p-invalid': v$.type.$invalid && submitted }" />
            <small v-if="
              (v$.type.$invalid && submitted) || v$.type.$pending.$response
            " class="p-error">{{
  v$.type.required.$message.replace(
    "Value is required",
    "Typ ist ein Pflichtfeld"
  )
}}</small>
          </div>

          <div class="field">
            <label for="description">Beschreibung</label>
            <InputText id="description" type="text" v-model="v$.description.$model" placeholder="Beschreibung" />
          </div>

          <div class="field">
            <label for="tags">Tags</label>
            <Chips id="tags" v-model="v$.tags.$model" separator="," addOnBlur:true />
          </div>

          <!-- <div class="field">
            <label for="date">Datum</label>
            <Chips id="date" v-model="v$.date.$model" separator="," addOnBlur:true />
          </div> -->

          <Buttons :style="clientColor" label="Absenden" class="mt-2" type="submit" autofocus />
        </form>
      </div>
    </RochefortDialog>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { isEmpty } from 'lodash';

import Buttons from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { FilterMatchMode } from "primevue/api";
import ConfirmDialog from "primevue/confirmdialog";
import RochefortDialog from "primevue/dialog";
import Chips from "primevue/chips";
// import Calendar from 'primevue/calendar';

import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";

import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import TargetService from "../services/target.service";
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiPencil, mdiDelete, mdiMagnify, mdiRefresh } from '@mdi/js';

export default {
  name: "TargetsView",
  components: {
    SvgIcon,
    Buttons,
    DataTable,
    Column,
    ConfirmDialog,
    RochefortDialog,
    Dropdown,
    InputText,
    Chips,
    // Calendar
  },
  targetService: null,
  data() {
    return {
      pencil: mdiPencil,
      garbagebin: mdiDelete,
      magnify: mdiMagnify,
      refreshicon: mdiRefresh,
      columns: null,
      filters: null,
      loading: true,
      displayCreateModal: false,
      targets: [],
      newTarget: {
        targetTypes: [{ name: "S3", code: "S3" }, { name: "SFTP", code: "SFTP" }],
      },
    };
  },
  setup() {
    const state = reactive({
      type: "",
      description: "",
      tags: "",
      date: "",
    });

    const rules = {
      type: { required },
      description: {},
      tags: {},
      date: {},
    };

    const submitted = ref(false);
    const type = ref();

    const v$ = useVuelidate(rules, state);

    const targetService = new TargetService();

    return { state, v$, targetService, submitted, type };
  },
  created() {
    this.targetService = new TargetService();

    this.columns = [
      { field: "TargetId", header: "ID" },
      { field: "type", header: "Typ" },
      { field: "description", header: "Beschreibung" },
      // { field: "tags", header: "Tags" }
    ];

    this.initFilters();
  },
  mounted() {
    this.refresh();
  },
  computed: {
    clientColor() {
      const { background_color, text_color } = this.$store.state.auth.jobFlowClient ?? {};

      if (isEmpty(background_color)) {
        return {
          'backgroundColor': '#ff0000',
          'color': '#fff'
        };
      } else {
        return {
          'backgroundColor': background_color,
          'color': text_color
        };
      }
    }
  },
  methods: {
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    clearFilter() {
      this.initFilters();
    },
    openCreateModal() {
      this.displayCreateModal = true;
    },
    closeModal() {
      this.displayCreateModal = false;
    },
    refresh() {
      this.loading = true;
      this.targetService.getTargets().then((data) => {
        this.targets = data;
        this.loading = false;
      });
    },
    handleSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      this.targetService
        .putTarget({
          type: this.state.type.code,
          description: this.state.description,
          tags: this.state.tags,
          date: this.state.date,
          filehandling: {
            unzip: false,
          },
        })
        .then(() => {
          this.closeModal();
          this.refresh();
        });
    },
    handleClickEdit(rowIndex) {
      const result = this.targets.find((item) => item.TargetId === rowIndex);
      // const targetId = this.targets[rowIndex].TargetId;
      const targetId = result.TargetId;
      /* */this.$router.push({
         //path: `/edit-target/${this.targets[rowIndex].TargetId}`
         name: "edit-target",
         //params: { targetId: this.targets[rowIndex].TargetId },
         params: { targetId },
       });
    },
    handleClickDelete(rowIndex) {
      const result = this.targets.find((item) => item.TargetId === rowIndex);
      const targetId = result.TargetId;
      // message: `Möchten Sie wirklich Target "${this.targets[rowIndex].TargetId}" unwiderruflich löschen?`,
      // .deleteTarget(this.targets[rowIndex].TargetId)
      this.$confirm.require({
        message: `Möchten Sie wirklich Target "${targetId}" unwiderruflich löschen?`,
        header: "Target löschen",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        acceptLabel: "Ja",
        rejectLabel: "Nein",
        accept: () => {
          
          this.targetService
            .deleteTarget(targetId)
            .then(() => {
              this.refresh();
            });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.target-create-btn {
  // margin-bottom: 1.5rem;
  text-align: right;
}

.form-new-target {
  min-width: 450px;
  margin-top: 2rem;

  .field {
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 960px) {
  .form-new-target {
    width: 80%;
  }
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even) {
  background: #817e7e !important;
}

:deep(.p-datatable) {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
}

:deep(.p-datatable-wrapper) {
  overflow-y: visible;
  overflow-x: auto;
}
</style>